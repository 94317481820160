import { HttpContext, HttpContextToken } from '@angular/common/http';

export const NO_LOCALE = new HttpContextToken<boolean>(() => false);

/**
 * @returns {object} retorno set context
 */
export function skipLocale() {
  return new HttpContext().set(NO_LOCALE, true);
}
