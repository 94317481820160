import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { emptyString } from 'app/constants/global.constants';
import { NO_LOCALE } from 'app/functions/skip-locale.function';

import { MTranslateService } from '@mercadona/core/translate';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private translateService: MTranslateService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const locale = this.translateService.getActiveLocale();
    if (!req.params.get('localeLanguage') && !req.context.get(NO_LOCALE) && !req.url.includes('[skipLocale]')) {
      const cloneReq = req.clone({
        params: req.params.append('localeLanguage', locale)
      });
      return next.handle(cloneReq);
    }
    if (req.url.includes('[skipLocale]')) {
      const dupReq = req.clone({ url: req.url.replace('[skipLocale]', emptyString) });
      return next.handle(dupReq);
    }

    return next.handle(req);
  }
}
