import { inject, Injector, NgModule, Provider } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, ROUTES, Routes } from '@angular/router';
import { BehaviorSubject, catchError, from, map, of, switchMap } from 'rxjs';

import { HeaderModule } from '@components/header/header.module';

import { MAuthGuard, MAuthService } from '@mercadona/core/auth';

import { ROLES } from './constants/auth.interface';
import { emptyString } from './constants/global.constants';
import { AuthGuard } from './guards/auth.guard';
import { HomeService } from './repository/services/home/home.service';
import { HOME_API_SERVICE_TOKEN } from './tokens/home-api-service-token';
import { PERMISSIONS_TOKEN } from './tokens/permissions-token';
import { SUPPLIER_TOKEN } from './tokens/supplier-token';
import { AssortmentApiService } from './use-cases/services/assortment/assortment-api.service';
import { HomeAccountantApiService } from './use-cases/services/home-accountant-api/home-accountant-api.service';
import { HomeApiService, IHomeApiService } from './use-cases/services/home-api/home-api.service';
import { HomeProvisioningApiService } from './use-cases/services/home-provisioning-api/home-provisioning-api.service';
import { HomeTaxApiService } from './use-cases/services/home-tax-api/home-tax-api.service';
// IMPORTANT: Do not use the "/error" route as it will be overwritten by the Core module. More info:
// https://mus.mercadona.com/39eafa15b/v/0/p/014302-page-error
// IMPORTANT: Do not use the "/monitoring_nginx" route as it will be overwritten by Nginx Configuration
// IMPORTANT: Do nout user the "/callback" as it will be overwritten by the Token module. More info:
// https://mus.mercadona.com/39eafa15b/v/0/p/043ae9-rutas-no-permitidas

type RoutesFactoryReturnType = typeof routesFactory;

/**
 *
 * @param {AssortmentApiService} assortmentApiService servicio surtido
 * @param {object} supplierBS datos proveedor
 * @returns {RoutesFactoryReturnType} routes routes
 */
function routesFactory(
  assortmentApiService: AssortmentApiService,
  supplierBS: BehaviorSubject<
    | {
        supplierName: string;
        supplierId: string;
      }
    | undefined
  >
) {
  const routes: Routes = [
    ...(() => {
      if (window.location.pathname.includes('/cdv90/supplier')) {
        return [
          {
            path: 'supplier',
            canActivate: [MAuthGuard, (_snapshot: ActivatedRouteSnapshot) => inject(MAuthService).isAuthenticated$],
            canLoad: [MAuthGuard, (_snapshot: ActivatedRouteSnapshot) => inject(MAuthService).isAuthenticated$],
            loadChildren: () =>
              assortmentApiService
                .getSupplierName()
                .pipe(catchError(() => of(null)))
                .pipe(
                  switchMap((result) => {
                    supplierBS.next(result?.data ?? undefined);
                    return from(import('./pages/proveedor/home-proveedor/home-proveedor-page.module')).pipe(
                      map((m) => m.HomeProveedorPageModule)
                    );
                  })
                )
          }
        ];
      }
      return [
        {
          path: emptyString,
          canActivate: [MAuthGuard, AuthGuard],
          canLoad: [MAuthGuard, AuthGuard],
          loadChildren: () => import('./pages/home/home-page.module').then((m) => m.HomePageModule)
        }
      ];
    })()
  ];
  return routes;
}

@NgModule({
  imports: [RouterModule.forRoot([])],
  exports: [RouterModule, HeaderModule],
  providers: (() => {
    const supplierBS = new BehaviorSubject<
      | {
          supplierName: string;
          supplierId: string;
        }
      | undefined
    >(undefined);
    const permissionsBS = new BehaviorSubject<string[]>([]);
    const res: Provider[] = [
      {
        provide: PERMISSIONS_TOKEN,
        useValue: permissionsBS
      },
      {
        provide: SUPPLIER_TOKEN,
        useValue: supplierBS
      },
      {
        provide: HOME_API_SERVICE_TOKEN,
        useFactory: (injector: Injector, homeService: HomeService) => {
          const permissions = injector.get(PERMISSIONS_TOKEN);
          if (permissions.value.some((p) => [ROLES.fiscal].includes(p))) {
            return new HomeTaxApiService(homeService) as IHomeApiService;
          } else if (permissions.value.some((p) => [ROLES.contable].includes(p))) {
            return new HomeAccountantApiService(homeService) as IHomeApiService;
          } else if (permissions.value.some((p) => [ROLES.aprovisionamiento].includes(p))) {
            return new HomeProvisioningApiService(homeService) as IHomeApiService;
          }
          return new HomeApiService(homeService) as IHomeApiService;
        },
        deps: [Injector, HomeService]
      },
      {
        provide: ROUTES,
        useFactory: routesFactory,
        multi: true,
        deps: [AssortmentApiService, SUPPLIER_TOKEN]
      }
    ];
    return res;
  })()
})
export class AppRoutingModule {}
